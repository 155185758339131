import Link from 'next/link';
import classNames from 'classnames';
import styles from './styles.module.css';
import { Button } from '../Button';
import Image from 'next/image';
import { getStandardCardHoverBlockType } from '@/features/carousel/model';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';

export const StandardCard = (props: {
    id: number;
    slug: string;
    href: string;
    background: string;
    hoverBlock?: {
        type?: 'partial' | 'full' | 'none' | null;
        title?: string;
        description?: string;
        cta?: string;
        overrides?: {
            color?: string | null;
            background?: string | null;
            cta?: { color?: string | null; background?: string | null; border?: string | null } | null;
        };
    } | null;
    loading: 'eager' | 'lazy' | undefined;
    style?: any;
    badge?: { label: string; backgroundColor: string; labelColor: string } | null;
}) => {
    const style = cleanObj({
        '--hover-block-color': props?.hoverBlock?.overrides?.color,
        '--hover-block-background': props?.hoverBlock?.overrides?.background,
    });

    // Replace the parsing logic with a simple function to strip HTML tags
    const stripHtml = (html: string) => {
        return html.replace(/<[^>]*>/g, '');
    };

    const description = stripHtml(props?.hoverBlock?.description ?? '');

    function cleanObj(obj: any): { [key: string]: string } {
        Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
        return obj;
    }
    const { isMobile, isDesktop } = useDeviceDetector();
    const isMobileDetected = isMobile();
    const hoverBlockType = getStandardCardHoverBlockType(isMobileDetected, props?.hoverBlock?.type);

    //extra <div / is needed to separate the badge from the background and not be affected by the overflow:hidden
    return (
        <Link
            style={props?.style}
            className={styles.link}
            href={props.href}
            target={'_self'}
            aria-label="Clickable card"
            prefetch={false}
        >
            <span
                className="ark-ui-atom-badge card_badge"
                aria-label="card badge"
                style={{
                    backgroundColor: props?.badge?.backgroundColor,
                    color: props?.badge?.labelColor,
                }}
            >
                {props.badge?.label}
            </span>
            <div className={styles.cardWrapper}>
                <img className={styles.background} src={props.background} loading={props.loading} />
                <div
                    className={classNames(styles['hover-block'], {
                        [styles.partial]: hoverBlockType === 'partial',
                        [styles.full]: hoverBlockType === 'full',
                    })}
                    style={style}
                >
                    <p className={styles.title}>{props?.hoverBlock?.title}</p>
                    <p className={styles.description}>{description}</p>
                    {hoverBlockType === 'full' && (
                        <Button
                            variation="outline"
                            overrides={[
                                {
                                    state: 'normal',
                                    background: props?.hoverBlock?.overrides?.cta?.background,
                                    color: props?.hoverBlock?.overrides?.cta?.color,
                                    border: props?.hoverBlock?.overrides?.cta?.border,
                                },
                            ]}
                            focusable={false}
                            staticStyling={true}
                        >
                            <Image
                                className={styles['play-icon']}
                                width={24}
                                height={24}
                                src={'/assets/play.svg'}
                                alt="Play icon"
                            />
                            {props?.hoverBlock?.cta}
                        </Button>
                    )}
                </div>
            </div>
        </Link>
    );
};
